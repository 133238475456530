import {
  Box,
  Dialog,
  DialogActions,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateContract } from "../../api/endpoints/contracts";
import { CancelButton, HeaderBox } from "../../assets/styles/tables";
import Loading from "../../common/Loading";
import ContractEditDialog from "../../components/dialog/ContractEditDialog";
import ContractsEditTable from "../../components/table/ContractsEditTable";
import {
  setContractToEdit,
} from "../../features/contracts/contractSlice";

const ContractDialog = (dialogDataProps) => {
  const dispatch = useDispatch();
  const { open, handleClose, editMode, save, setSave } = dialogDataProps;

  // States from Redux
  const contractsFromStore = useSelector(
    (state) => state.contracts.contractsToEditByGroup
  );
  const contractToEdit = useSelector((state) => state.contracts.contractToEdit);
  const { groups } = useSelector((state) => state.groups);
  const loadingContracts = useSelector(
    (state) => state.contracts.loadingContracts
  );

  // Local states
  const [displayedContracts, setDisplayedContracts] = useState([]);
  const [originalContracts, setOriginalContracts] = useState([]);
  const [openEditContract, setOpenEditContract] = useState(false);
  const [pageContract, setPageContract] = useState(1);
  const [selectedGroupUuid, setSelectedGroupUuid] = useState(0);
  const ITEMS_PER_PAGE = 5;

  // Update local states when store data changes
  useEffect(() => {
    if (Array.isArray(contractsFromStore) && contractsFromStore.length > 0) {
      setOriginalContracts(contractsFromStore);
      setDisplayedContracts(contractsFromStore);
    }
  }, [contractsFromStore]);

  const getCurrentPageItemsContractsTable = () => {
    if (!Array.isArray(displayedContracts) || displayedContracts.length === 0) {
      return [];
    }
    const startIndex = (pageContract - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return displayedContracts.slice(startIndex, endIndex);
  };

  const handleCloseEditContract = () => {
    if (contractToEdit) {
      dispatch(updateContract(contractToEdit));
    }
    setOpenEditContract(false);
  };

  const handleSelectFilterChange = (event) => {
    const newGroupUuid = event.target.value;
    setSelectedGroupUuid(newGroupUuid);
    setPageContract(1);

    if (newGroupUuid === 0) {
      setDisplayedContracts(originalContracts);
    } else {
      const filtered = originalContracts.filter(
        (contract) => contract.uuid_group === newGroupUuid
      );
      setDisplayedContracts(filtered);
    }
  };

  const handleInputFilterChange = (event) => {
    const searchText = event.target.value.toUpperCase();
    let filtered;

    if (selectedGroupUuid === 0) {
      filtered = originalContracts.filter((contract) =>
        contract.contract_number.includes(searchText)
      );
    } else {
      filtered = originalContracts.filter(
        (contract) =>
          contract.uuid_group === selectedGroupUuid &&
          contract.contract_number.includes(searchText)
      );
    }

    setDisplayedContracts(filtered);
    setPageContract(1);
  };

  const handleInputChangeEditContract = (event) => {
    const { name, value } = event.target;
    if (contractToEdit) {
      dispatch(setContractToEdit({ ...contractToEdit, [name]: value }));
    }
  };

  const handleSelectChangeEditContract = (event) => {
    const { name, value } = event.target;
    if (contractToEdit) {
      dispatch(setContractToEdit({ ...contractToEdit, [name]: value }));
    }
  };

  const handleSaveEditContract = async () => {
    if (contractToEdit) {
      await dispatch(updateContract(contractToEdit));
      setSave(!save);
      handleCloseEditContract();
    }
  };

  const handleEdit = (contract) => {
    dispatch(setContractToEdit(contract));
    setOpenEditContract(true);
  };

  const props = {
    contractsDataTable: displayedContracts,
    handleEdit,
    getCurrentPageItems: getCurrentPageItemsContractsTable,
    ITEMS_PER_PAGE,
    page: pageContract,
    setPage: setPageContract,
    totalItems: displayedContracts.length,
  };

  const dialogEditProps = {
    openEditContract,
    handleCloseEditContract,
    handleClose,
    handleInputChangeEditContract,
    handleSelectChangeEditContract,
    handleSaveEditContract,
    save,
    setSave,
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={false}
      sx={{ borderRadius: "20px" }}
    >
      {loadingContracts ? (
        <div
          style={{
            display: "flex",
            borderRadius: "8px",
            height: "400px",
            width: "400px",
            boxShadow: "lightgrey 5px 1px 15px 10px",
            backgroundColor: "#f2f2f2",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          <HeaderBox
            sx={{
              borderRadius: "8px",
              height: "100px",
              marginTop: "20px",
              marginLeft: "55px",
              marginRight: "55px",
              boxShadow: "lightgrey 5px 1px 15px 10px",
              backgroundColor: "#f2f2f2",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "5%",
                flexGrow: 1,
              }}
            >
              <Typography sx={{ marginRight: 2 }}>
                Seleccionar grupo:
              </Typography>
              <Select
                variant="outlined"
                value={selectedGroupUuid}
                onChange={handleSelectFilterChange}
                sx={{ marginRight: 5, width: 150 }}
              >
                <MenuItem value={0}>Seleccionar</MenuItem>
                {groups.map((group) => (
                  <MenuItem key={group.uuid} value={group.uuid}>
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
              <Typography sx={{ marginRight: 2 }}>
                Buscar por n° de contrato:
              </Typography>
              <TextField
                sx={{ marginRight: 1, width: 150 }}
                variant="outlined"
                onChange={handleInputFilterChange}
              />
            </Box>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "4%",
                paddingRight: "4%",
                marginBottom: "3%",
              }}
            >
              <CancelButton
                onClick={handleClose}
                variant="contained"
                color="tertiary"
                sx={{
                  height: "40px",
                  boxShadow: "lightgrey 5px 0px 20px 10px",
                }}
              >
                Salir
              </CancelButton>
            </DialogActions>
          </HeaderBox>
          <div style={{ paddingBottom: "4%", paddingLeft: "2%" }}>
            <ContractsEditTable {...props} />
            <ContractEditDialog {...dialogEditProps} />
          </div>
        </>
      )}
    </Dialog>
  );
};

export default ContractDialog;
